import React, { useState } from "react";
import {
    ComposableMap,
    Geographies,
    Geography,
    Marker
} from "react-simple-maps";
import { Box } from '@mui/material';

const geoUrl = 'https://cartomap.github.io/nl/wgs84/provincie_2021.topojson';

const markerCoordinates = {
    "Groningen": [6.40, 53.26],
    "Fryslân": [5.55, 53.10],
    "Drenthe": [6.5, 52.8],
    "Zeeland": [3.6, 51.1 ],
    "Gelderland": [5.8, 52.10],
    "Flevoland": [5.2, 52.45],
    "Limburg": [5.65, 51.25],
    "Utrecht": [5.0, 52.05],
    "Noord-Holland": [3.6, 52.55],
    "Zuid-Holland": [4.1, 51.86],
    "Noord-Brabant": [4.8, 51.58],
    "Overijssel": [6.15, 52.40]
}


const ProvinceMap = (props) => {
    const [showProvinceData, setShowProvinceData] = useState(false);
    const [activeProvinceData, setActiveProvinceData] = useState(null);

    const provinceData = props.provinces;
    const provinceDataYesterday = props.provincesYesterday;

    const totalCases = activeProvinceData ? provinceData[activeProvinceData.properties.statnaam.replace('-','_').replace('Fryslân','Frysl_n')] : null;
    const totalCasesYesterday = activeProvinceData ? provinceDataYesterday[activeProvinceData.properties.statnaam.replace('-','_').replace('Fryslân','Frysl_n')]: null;

    return (
        <Box
            sx={{
                position: 'relative',
                '& > svg': {
                    height: '75vh'
                },
                '& > svg > g > path:focus': {
                    outline: 'none'
                }
            }}
        >
            <ComposableMap
                projection="geoAzimuthalEqualArea"
                projectionConfig={{
                    rotate: [-5.25, -52.0, 0],
                    scale: 10000
                }}
            >
                <Geographies geography={geoUrl}>
                    {({ geographies }) =>
                        <>
                            {geographies.map(geo => {
                                return (
                                    <Geography
                                        key={geo.rsmKey}
                                        geography={geo}
                                        fill="orange"
                                        stroke="#000"
                                        onMouseEnter={() => {setShowProvinceData(true); setActiveProvinceData(geo);}}
                                        onMouseLeave={() => setShowProvinceData(false)}
                                    />
                                );
                            })};

                            {geographies.map(geo => (
                                <g
                                    key={geo.rsmKey + "-name"}
                                    style={{pointerEvents: 'none'}}
                                >
                                    <Marker
                                        coordinates={markerCoordinates[geo.properties.statnaam]}>
                                        <text fontSize={14}>
                                            {geo.properties.statnaam.replace('Fryslân','Friesland')}
                                        </text>
                                    </Marker>
                                </g>
                            ))};
                        </>
                    }
                </Geographies>
            </ComposableMap>

            {showProvinceData && (
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        left: '0',
                        pointerEvents: 'none',
                        position: 'absolute',
                        top: '25vh',
                        width: '100%'
                    }}
                >
                    <Box
                        sx={{
                            bgcolor: '#fff',
                            border: '1px solid #000',
                            borderRadius: '15px',
                            color: '#000',
                            height: '50%',
                            padding: '20px',
                            width: {
                                xs: '40%',
                                md: '60%'
                            }
                        }}
                    >
                        <Box
                            sx={{
                                fontSize: 'lFontSize',
                                fontWeight: 'bold'
                            }}
                        >
                            {activeProvinceData.properties.statnaam.replace('Fryslân','Friesland')}
                        </Box>
                        <Box sx={{fontSize: 'mdFontSize'}}>Besmettingen vandaag: <Box sx={{display: 'inline-block', color: totalCasesYesterday > totalCases ? '#4BB543' : '#a30808'}}>{totalCases}</Box></Box>
                        <Box sx={{fontSize: 'mdFontSize'}}>Besmettingen gisteren: <Box sx={{display: 'inline-block', color: totalCasesYesterday > totalCases ? '#a30808' : '#4BB543'}}>{totalCasesYesterday}</Box></Box>
                        </Box>
                </Box>
            )}
        </Box>
    );
};

export default ProvinceMap;
