import React from 'react';
import { Box } from '@mui/material';
import { graphql, useStaticQuery } from 'gatsby';
import ProvinceMap from '../components/ProvinceMap';

const Provincies = () => {
    const infectionsData = useStaticQuery(graphql`
        query {
            infectionsData {
                provinceCases {
                    Drenthe
                    Flevoland
                    Frysl_n
                    Gelderland
                    Groningen
                    Limburg
                    Noord_Brabant
                    Noord_Holland
                    Overijssel
                    Utrecht
                    Zeeland
                    Zuid_Holland
                }

                provinceCasesYesterday {
                    Drenthe
                    Flevoland
                    Frysl_n
                    Gelderland
                    Groningen
                    Limburg
                    Noord_Brabant
                    Noord_Holland
                    Overijssel
                    Utrecht
                    Zeeland
                    Zuid_Holland
                }
            }
        }
    `)

    const provinces = infectionsData.infectionsData.provinceCases;
    const provincesYesterday = infectionsData.infectionsData.provinceCasesYesterday;

    return (
        <Box
            sx={{
                maxHeight: '100%'
            }}
        >

            <ProvinceMap provinces={provinces} provincesYesterday={provincesYesterday} />
        </Box>
    )
};

export default Provincies;